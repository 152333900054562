import { createFeature, createReducer, on } from '@ngrx/store';
import { setOnboarding } from './onboarding.actions';

export interface Onboarding {
	analyticsWatched: boolean;
}

const initialState: Onboarding = {
	analyticsWatched: true,
};

const reducer = createReducer(
	initialState,
	on(setOnboarding, (state, action) => ({ ...state, ...action.value }))
);

export const onboardingFeature = createFeature({
	name: 'onboarding',
	reducer,
});
