import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationSettings } from '../../general/interfaces/application-settings.interface';
import { OnboardingService } from '../../general/services/onboarding.service';
import { saveOnboarding, setOnboarding } from './onboarding.actions';

@Injectable()
export class OnboardingEffects {
	constructor(
		private actions$: Actions,
		private onboardingService: OnboardingService
	) {}

	saveOnboarding$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveOnboarding),
				mergeMap((action) => {
					return this.onboardingService
						.saveOnboardingOnServer(action.value)
						.pipe(
							map((filter: ApplicationSettings) =>
								setOnboarding({ value: filter.onboarding.analyticsWatched })
							)
						);
				})
			),
		{ dispatch: true }
	);
}
