import {
	AGILOX_API_ENDPOINT,
	AppNavMenuPointsService,
	AuthenticationInterceptor,
	ChunkLoadingFailedErrorHandler,
	ENVIRONMENT,
	GOOGLE_TAG_MANAGER_ID,
	GoogleAnalyticsService,
	initializeTranslations,
	MENU_POINTS,
	MenuPointService,
	ROLE,
	TranslationsLoader,
} from '@agilox/common';
import {
	ButtonToggleContentDirective,
	HelpContentComponent,
	InputModule,
	NavListModule,
	NavModule,
	OnboardingComponent,
	UserNotificationsService,
	VehiclesSelectService,
} from '@agilox/ui';
import { environment } from '@analytics/env/environment';
import { DatePipe } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CacheOptimizerInterceptor } from './general/interceptors/cache-optimizer.interceptor';
import { ErrorHandlerInterceptor } from './general/interceptors/error-handler.interceptor';
import { ResponseInterceptor } from './general/interceptors/response.interceptor';
import { MaterialModule } from './general/material/material.module';
import { AppsettingsService } from './general/shared/services/appsettings/appsettings.service';
import { AuthenticationService } from './general/shared/services/authentication/authentication.service';
import { desktopMenuPoints } from './general/shared/services/authentication/desktop-menupoints';
import { mobileMenuPoints } from './general/shared/services/authentication/mobile-menupoints';
import { SharedModule } from './general/shared/shared.module';
import { WidgetsModule } from './general/widgets/widgets.module';
import { MyHammerConfig } from './hammer-gesture-config';
import { AppSettingsEffects } from './store/app-settings.effects';
import { DateFilterEffects } from './store/date-filter/date-filter.effects';
import { dateFilterFeature } from './store/date-filter/date-filter.state';
import { ModeFilterEffect } from './store/mode-filter/mode-filter.effect';
import { modeFilterFeature } from './store/mode-filter/mode-filter.state';
import { OnboardingEffects } from './store/onboarding/onboarding.effect';
import { onboardingFeature } from './store/onboarding/onboarding.state';
import { VehicleFilterEffects } from './store/vehicles-filter/vehicle-filter.effects';
import { vehicleFilterFeature } from './store/vehicles-filter/vehicles-filter.state';

@NgModule({
	declarations: [AppComponent],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		SharedModule,
		MaterialModule,
		HammerModule,
		// translation
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: TranslationsLoader,
				deps: [HttpClient],
			},
		}),
		WidgetsModule,
		NavListModule,
		NavModule,
		InputModule,
		HelpContentComponent,
		ButtonToggleContentDirective,
		OnboardingComponent,
	],
	providers: [
		provideHttpClient(withInterceptorsFromDi()),
		provideStore(),
		provideState(dateFilterFeature),
		provideState(vehicleFilterFeature),
		provideState(modeFilterFeature),
		provideState(onboardingFeature),
		provideEffects([
			AppSettingsEffects,
			VehicleFilterEffects,
			DateFilterEffects,
			ModeFilterEffect,
			OnboardingEffects,
		]),
		provideStoreDevtools(),
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		TranslateService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeTranslations,
			deps: [TranslateService],
			multi: true,
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		// insert errorhandlers after sentry, otherwise sentry interrupts too early in error-handling
		{
			provide: ErrorHandler,
			useClass: ChunkLoadingFailedErrorHandler,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthenticationInterceptor,
			multi: true,
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: MyHammerConfig,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CacheOptimizerInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi: true,
		},
		// Interceptor for the response from the API
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ResponseInterceptor,
			multi: true,
		},
		DatePipe,
		{
			provide: APP_INITIALIZER,
			useFactory: (authenticationService: AuthenticationService) => () =>
				authenticationService.getUser(),
			deps: [AuthenticationService],
			multi: true,
		},
		AppNavMenuPointsService,
		{
			provide: MENU_POINTS,
			useValue: {
				desktop: desktopMenuPoints,
				mobile: mobileMenuPoints,
			},
		},
		MenuPointService,
		{
			provide: ROLE,
			useFactory: (settings: AppsettingsService) => settings.onRoleChanged$,
			deps: [AppsettingsService],
		},
		{
			provide: ENVIRONMENT,
			useValue: environment,
		},
		{
			provide: GOOGLE_TAG_MANAGER_ID,
			useValue: 'GTM-TWW3MLW',
		},
		GoogleAnalyticsService,
		{
			provide: AGILOX_API_ENDPOINT,
			useValue: environment.server,
		},
		{
			provide: UserNotificationsService,
			deps: [AGILOX_API_ENDPOINT],
		},
		{
			provide: VehiclesSelectService,
			deps: [AGILOX_API_ENDPOINT],
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
