import { environment } from '@analytics/env/environment';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppsettingsService } from '../shared/services/appsettings/appsettings.service';

@Injectable({
	providedIn: 'root',
})
export class OnboardingService {
	private http = inject(HttpClient);
	private appsettings = inject(AppsettingsService);

	saveOnboardingOnServer(watched: boolean): Observable<any> {
		const appsettings = {
			...this.appsettings.webAppSettings,
			onboarding: {
				...this.appsettings.webAppSettings.onboarding,
				analyticsWatched: watched,
			},
		};
		const reqObject = { webapp_settings: JSON.stringify(appsettings) };
		return this.http.post(environment.server + '/v2/User/saveSettings', reqObject);
	}
}
