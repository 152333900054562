import { Role, User } from '@agilox/common';
import { environment } from '@analytics/env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	BehaviorSubject,
	Observable,
	Observer,
	PartialObserver,
	Subject,
	Subscription,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { DateFilter } from '../../../../store/date-filter/date-filter.state';
import { ApplicationSettings } from '../../../interfaces/application-settings.interface';
import { AppSettings } from './app-settings';
import { DateSelector } from './date-selector.interface';
import { Mode } from './mode.enum';

/**
 * provides all informations to the appsettings
 */
@Injectable({
	providedIn: 'root',
})
export class AppsettingsService {
	/** user */
	user: User;
	/** webappsettings */
	webAppSettings: AppSettings;
	/** is initialized */
	isInitialized = false;
	/** is help popup visible */
	isHelpPopupVisible = false;

	/** date selector */
	get dateSelector(): DateSelector {
		return this.webAppSettings?.dateSelector;
	}

	/** gets next if done initializing */
	private onInitializedSubject = new Subject<void>();
	/** gets invoked if DateSelectorChanged */
	public onDateSelectorChanged = new Subject<DateSelector>();
	/** gets invoked if the mode gets changed */
	private onModeChanged = new Subject<Mode>();
	/** gets invoked if a machines gets selected */
	public onMachineSelected = new Subject<Array<string> | string>();
	/** gets invoked when a new machine gets selected with the each togglers */
	private onEachMachineSelectedSubject = new Subject<string>();
	/** gets invoked on user changed */
	private onUserChangedSubject = new Subject<User>();
	/** save settings subscription */
	private saveSettingsSubscription: Subscription;

	private roleChanged: BehaviorSubject<string> = new BehaviorSubject<string>('');
	onRoleChanged$: Observable<string> = this.roleChanged.asObservable();

	/**
	 * constructs the appsettings service
	 * @param translate needed for translation
	 * @param http need for server requests
	 */
	constructor(
		private translate: TranslateService,
		private http: HttpClient
	) {}

	/**
	 * register an observer for notifing when the app is initialized
	 * @param observer gets notified when the app is initialized
	 */
	registerOnInitialized(observer: Observer<void>): Subscription {
		return this.onInitializedSubject.subscribe(observer);
	}

	/**
	 * Initializes from the login response
	 * @param response repsonse from login request
	 */
	setFromResponse(data: any): void {
		if (data.userProfile) {
			this.user = data.userProfile;
			this.roleChanged.next(this.user.role);
			this.webAppSettings = AppSettings.generateFromJson(
				JSON.parse(data.userProfile.webapp_settings)
			);
			this.setLanguage();
			// all appsettings are loaded, the app is initialized
			this.isInitialized = true;
			this.onInitializedSubject.next();
		}
	}

	/**
	 * saves the appsettings on the server
	 */
	saveAppSettingsOnServer(): void {
		const reqObject = { webapp_settings: JSON.stringify(this.webAppSettings.toJson()) };
		// saving the settings
		this.saveSettingsSubscription?.unsubscribe();
		this.saveSettingsSubscription = this.http
			.post(environment.server + '/v2/User/saveSettings', reqObject)
			.subscribe();
	}

	saveAppSettings(webappSettings) {
		const reqObject = { webapp_settings: JSON.stringify(webappSettings.toJson()) };

		return this.http.post(environment.server + '/v2/User/saveSettings', reqObject).pipe(
			map((_) => {
				this.onMachineSelected.next(webappSettings.selectedMachines);
				this.onModeChanged.next(webappSettings.mode);
				return webappSettings;
			})
		);
	}

	/**
	 * Generates a standard required object
	 * @returns generates a standard reqObject for requests
	 */
	generateReqObject(allMachine: boolean = false): any {
		const { startDate, endDate, granularity, shiftOn, shiftStart, shiftEnd, shiftDays, mode } =
			this.dateSelector;
		const { selectedMachines, dateSelector: webDateSelector } = this.webAppSettings;

		let serial = selectedMachines;

		// If in "each" mode and show-all-popup is not open, only load the machineToShow
		if (mode === Mode.each && !allMachine) {
			serial = selectedMachines.length > 0 ? selectedMachines.slice(0, 1) : [];
		}

		const res: any = {
			serial,
			from: startDate / 1000,
			to: endDate / 1000,
			res: granularity,
			sum: mode === Mode.sum && !webDateSelector.combined,
		};

		// Add shift details if shiftOn is enabled
		if (shiftOn) {
			res.shift = {
				on: shiftOn,
				start: shiftStart / 1000,
				end: shiftEnd / 1000,
				days: shiftDays,
			};
		}

		return res;
	}

	/**
	 * Register an observer which gets notified
	 * @param observer observer you want to register
	 */
	registerOnDateSelector(observer: PartialObserver<DateSelector>): Subscription {
		return this.onDateSelectorChanged.subscribe(observer);
	}

	/**
	 * Register an observer which gets notified when the mode changes
	 * @param observer observer
	 */
	registerOnModeChanged(observer: PartialObserver<Mode>): Subscription {
		return this.onModeChanged.subscribe(observer);
	}

	/**
	 * gets invoked if a machine was selected
	 * @param observer gets notified
	 */
	registerOnMachineSelected(observer: PartialObserver<Array<string>>): Subscription {
		return this.onMachineSelected.subscribe(observer);
	}

	/**
	 * gets invoked if a new machine to display under the each mode is selected
	 * @param observer gets notified
	 */
	registerOnEachMachineSelected(observer: PartialObserver<string>): Subscription {
		return this.onEachMachineSelectedSubject.subscribe(observer);
	}

	/**
	 * updates user
	 * @param user user
	 */
	updateUser(user?: User) {
		if (user) {
			this.user = user;
		}
		this.onUserChangedSubject.next(user as User);
	}

	/**
	 * sets the language, either given via parameter or just applies the language of the userprofile
	 * @param language choosen language
	 */
	setLanguage(language?: string) {
		if (language) {
			this.user.lang = language;
			this.translate.use(language.toLowerCase());
		} else if (this.user) {
			this.translate.setDefaultLang(this.user.lang);
			this.translate.use(this.user.lang);
		} else {
			this.translate.setDefaultLang('en');
			this.translate.use('en');
		}
		this.updateUser(this.user);
	}

	/**
	 * sets machine to show
	 * @param machine machine serial
	 */
	machineToShowSelected(machine: string) {
		this.webAppSettings.machineToShow = machine;
		this.saveAppSettingsOnServer();
		this.onEachMachineSelectedSubject.next(machine);
	}

	/**
	 * registers an observer on:
	 *  * DateSelector
	 *  * MachineToShow selected
	 *  * Machine selected
	 *  * Mode changed
	 * @param observer observer
	 */
	registerOnWebappSettingChanged(
		observer: PartialObserver<string | Array<string> | DateSelector | Mode>
	): Array<Subscription> {
		const arr = new Array<Subscription>();
		arr.push(this.registerOnDateSelector(observer));
		arr.push(this.registerOnEachMachineSelected(observer));
		arr.push(this.registerOnMachineSelected(observer));
		arr.push(this.registerOnModeChanged(observer));
		return arr;
	}

	/**
	 * check if user is an internal user
	 * @returns true if the role is support or superuser
	 */
	isInternalUser(): boolean {
		return (
			this.user?.role === Role.support ||
			this.user?.role === Role.service ||
			this.user?.role === Role.superuser
		);
	}

	/**
	 * used for Google Analytics to check the user role
	 * @returns the user role
	 */
	getUserRole(): string {
		return this.user?.role;
	}

	getUserAppSettings(): Observable<ApplicationSettings> {
		return this.http
			.get<{
				userProfile: User & { webapp_settings: string };
			}>(environment.server + '/v2/User/getUserInfo')
			.pipe(
				map((v) => {
					const webappSettings = JSON.parse(v.userProfile.webapp_settings);
					const appsettings = this.checkWebAppSettings(webappSettings);
					return appsettings;
				}),
				map((appSettings: ApplicationSettings) => {
					//TODO remove after filter launch - necessary to bridge the gap between the old functionality and the new one
					appSettings.dateSelector.mode =
						appSettings.selectedMachines.length > 1 ? Mode.sum : Mode.each;
					return appSettings;
				})
			);
	}

	/** TODO: Remove after it is handled on the BE side */
	checkWebAppSettings(webappSettings: any) {
		if (webappSettings.onboarding.version !== undefined) {
			const alreadyWatched = webappSettings.onboarding.version === 2;
			webappSettings.onboarding = {
				myWatched: alreadyWatched,
				analyticsWatched: false,
			};
		}
		return webappSettings;
	}

	saveDateFilter(dateSelector: DateFilter): Observable<ApplicationSettings> {
		const mode = this.webAppSettings.dateSelector.mode;
		const combined = this.webAppSettings.dateSelector.combined;

		this.webAppSettings.dateSelector = { ...dateSelector, mode, combined };
		return this.saveAppSettings(this.webAppSettings);
	}

	saveVehiclesAndMode(selectedMachines: string[]) {
		const mode = selectedMachines.length > 1 ? Mode.sum : Mode.each;
		this.webAppSettings.dateSelector = { ...this.webAppSettings.dateSelector, mode };
		this.webAppSettings.selectedMachines = selectedMachines;
		this.webAppSettings.machineToShow = this.webAppSettings.selectedMachines[0] || undefined;
		return this.saveAppSettings(this.webAppSettings);
	}

	saveCombinedMode(combined: boolean) {
		this.webAppSettings.dateSelector = { ...this.webAppSettings.dateSelector, combined };
		return this.saveAppSettings(this.webAppSettings);
	}
}
